import MemoShapeBgPath1 from "@components/svg/shape-bg-path/shape-bg-path1";
import ShapeBgPath2 from "@components/svg/shape-bg-path/shape-bg-path2";
import ShapeBgPath3 from "@components/svg/shape-bg-path/shape-bg-path3";
import { styled } from "@infinitaslearning/pixel-design-system";

export const Shape1 = styled(MemoShapeBgPath1)`
  position: absolute;
  top: 0;
  right: 0;
  width: 124px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 330px;
  }
`;

export const Shape2 = styled(ShapeBgPath2)`
  position: absolute;
  left: 1px;
  bottom: 0;
  width: 222px;
  top: 224px;
`;

export const Shape3 = styled(ShapeBgPath3)`
  position: absolute;
  bottom: 0;
  right: 224px;
  width: 322px;
  height: 150px;
`;
