const BREAKPOINT_SMALL = 700;
const BREAKPOINT_MEDIUM = 900;
const BREAKPOINT_LARGE = 1000;

export const getMaxBreadcrumbs = (width: number, breadcrumbLength: number) => {
  if (width <= BREAKPOINT_SMALL) return 2;
  if (width <= BREAKPOINT_MEDIUM) return 3;
  if (width <= BREAKPOINT_LARGE) return 4;

  return breadcrumbLength + 1;
};
