import type { ShapeBgPathProps } from "@components/svg/shape-bg-path/shape-bg-path1";
import { type FC, memo } from "react";

const ShapeBgPath2: FC<ShapeBgPathProps> = ({ strokeColor, className }) => (
  // biome-ignore lint/a11y/noSvgWithoutTitle: This background element does not need a title
  <svg width={222} height={491} className={className}>
    <path
      d="M131.784 3.077s0 0 0 0c-47.886 7.586-154.39 81.728-319.51 222.424-35.14 29.94-45.216 80.044-24.383 121.241 23.64 46.75 56.957 81.637 99.952 104.66 95.792 51.297 214.774 48.47 268.604.18 27.403-24.582 45.587-56.679 54.552-96.291h0a100 100 0 00-4.956-59.884c-10.53-25.783-13.733-55.097-9.61-87.942 3.043-24.245 20.424-79.089 22.46-99.319.91-9.027 1.405-18.622 1.487-28.785h0c.34-42.31-33.683-76.884-75.993-77.225-4.22-.034-8.435.281-12.603.941z"
      stroke={strokeColor}
      fill="none"
      opacity={0.12}
    />
  </svg>
);

const MemoShapeBgPath2 = memo(ShapeBgPath2);
export default MemoShapeBgPath2;
