import { PlatformError } from "@components/platform-error/platform-error";
import { PlatformErrorCodes } from "@components/platform-error/platform-error-types";
import { Shape1, Shape2, Shape3 } from "@components/svg/shape-bg-path/shape-bg-path.styles";
import { Button, useTheme } from "@infinitaslearning/pixel-design-system";
import type { FC } from "react";

export type UnauthProps = {
  title: string;
  errorMessage: string | undefined;
  ctaText?: string;
  ctaUrl?: string;
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  error?: any;
};

const UnauthErrorComponent: FC<UnauthProps> = ({ title, errorMessage, ctaText, ctaUrl, error }) => {
  const theme = useTheme();
  const hideCTA = !ctaText || !ctaUrl;

  return (
    <PlatformError
      displayType="responsive_full"
      errorType="unauthenticated"
      // This is hardcoded as the only classroom error uses the data error type
      // Additionally if we pass this we get a `Text content does not match server-rendered HTML` error
      dataErrorType="classroom"
      errorCode={PlatformErrorCodes.Forbidden}
      customTitle={title}
      customMessage={errorMessage}
      CustomCTA={
        hideCTA ? null : (
          <Button size="medium" variant="text" href={ctaUrl}>
            {ctaText}
          </Button>
        )
      }
      error={error}
    >
      <Shape1 strokeColor={theme.pixel.color.palette.primary[20]} />
      <Shape2 strokeColor={theme.pixel.color.palette.primary[20]} />
      <Shape3 strokeColor={theme.pixel.color.palette.primary[20]} />
    </PlatformError>
  );
};

export default UnauthErrorComponent;
