import { BreadcrumbBar } from "@components/breadcrumb-bar/breadcrumb-bar";
import PrimaryNavigationBar from "@components/primary-navigation/primary-navigation";
import { Box, useTheme } from "@infinitaslearning/pixel-design-system";
import { Main } from "@styles/global-styling/global.styles";
import { getGridContainerSX } from "@utils/grid-helper";
import type React from "react";

type LayoutProps = {
  /** Show header only */
  showHeader?: boolean;
  /** Show header & breadcrumb bar */
  showBreadcrumbBar?: boolean;
  alignContent?: "stretch" | "start";
  children?: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({
  showBreadcrumbBar = false,
  showHeader = false,
  children,
  alignContent,
}) => {
  const theme = useTheme();
  return (
    <>
      {(showHeader || showBreadcrumbBar) && <PrimaryNavigationBar showLinks={true} />}
      {showBreadcrumbBar && <BreadcrumbBar />}
      <Main>
        <Box
          sx={getGridContainerSX({
            theme: theme,
            alignContent: alignContent,
            noGutter: true,
          })}
        >
          {children}
        </Box>
      </Main>
    </>
  );
};

export default Layout;
