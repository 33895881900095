import { getMaxBreadcrumbs } from "@components/breadcrumb-bar/breadcrumb-helper";
import BreadcrumbLink from "@components/breadcrumb-bar/breadcrumb-link";
import { Box, Breadcrumbs, Button, Typography } from "@infinitaslearning/pixel-design-system";
import { isDummy } from "@utils/is-dummy";
import { MAIN_ROUTES, SUB_ROUTES } from "@utils/router-helper";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useSelectedNavigation } from "@utils/use-selected-navigation";
import useWindowSize from "@utils/use-window-size";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import type { FC } from "react";

export const BAR_HEIGHT = 55;

export const BreadcrumbBar: FC = () => {
  const router = useRouter();
  const { t } = useTranslation("common", { keyPrefix: "breadcrumb-bar" });

  const subjectName: string | string[] | undefined = router.query.subject;
  const methodId: string | string[] | undefined = router.query.method;
  const methodGroupId: string | string[] | undefined = router.query["method-group"];
  const chapterId: string | string[] | undefined = router.query.chapter;

  const { isLoading, error, selected } = useSelectedNavigation();
  const showPupilsPlatformLink = useFeatureFlag("showPupilsPlatformLink", true);
  const { width } = useWindowSize();

  if (error) {
    //if this component crashes the main page will likely also crash, so we prevent double error pages.
    return <> </>;
  }

  let currentPage = "";
  if (!selected.selectedSubject) {
    currentPage = t("subject"); // TODO: Frank: Would be preferably to get these titles from the back-end, as they can depend on the subject/method
  } else if (!selected.selectedMethod) {
    currentPage = t("method");
  } else if (!selected.selectedMethodGroup) {
    currentPage = t("method-group");
  } else if (!selected.selectedChapter) {
    currentPage = t("items"); // Domi: Hard coded to a more generic term so it's always valid
  } else if (!selected.selectedParagraph) {
    currentPage = t("items"); // Domi: Hard coded to a more generic term so it's always valid
  } else {
    currentPage = t("learning-unit");
  }

  // Create a list of possible breadcrumbs, then filter the uninitialized ones
  const breadcrumbLinks: { link: string; title: string | undefined }[] = [
    { link: `${MAIN_ROUTES.content}${SUB_ROUTES.contentOverview}`, title: t("overview") },
    {
      link: `${MAIN_ROUTES.content}${SUB_ROUTES.contentOverview}`,
      title: selected.selectedMethod?.name,
    },
    {
      link: `${MAIN_ROUTES.content}/${subjectName}/${methodId}`,
      title: selected.selectedMethodGroup?.name,
    },
    {
      link: `${MAIN_ROUTES.content}/${subjectName}/${methodId}/${methodGroupId}`,
      title: selected.selectedChapter?.name,
    },
    {
      link: `${MAIN_ROUTES.content}/${subjectName}/${methodId}/${methodGroupId}/${chapterId}`,
      title: selected.selectedParagraph?.name,
    },
  ].filter((link) => {
    return !!link.title && !isDummy(link.title);
  });

  return (
    <Box
      data-usetiful="breadcrumb"
      sx={{
        p: 1,
        ml: 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Breadcrumbs
        data-usetiful="breadcrumb"
        sx={{ p: 1 }}
        separator="›"
        maxItems={getMaxBreadcrumbs(width, breadcrumbLinks.length)}
      >
        {!isLoading &&
          breadcrumbLinks.map((link) => {
            return <BreadcrumbLink key={link.link} link={link} />;
          })}
        {!isLoading && <Typography color="common.black">{currentPage}</Typography>}
      </Breadcrumbs>
      {showPupilsPlatformLink && (
        <Box>
          <Button
            // @ts-ignore This is allowed
            target="_blank"
            rel="noopener"
            href={t("pupilLinkHref")}
            variant="outlined"
            sx={{ whiteSpace: "nowrap", mr: 2 }}
          >
            <Typography variant="buttonSmall">{t("pupilLinkText")}</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};
