import type { Theme } from "@infinitaslearning/pixel-design-system";

type GridItemSXProps = {
  startColumn?: number;
  endColumn?: number;
  startRow?: number;
  endRow?: number;
};

export const getGridItemSX = ({
  startColumn = 1,
  endColumn = -1,
  startRow = 1,
  endRow = -1,
}: GridItemSXProps) => {
  return {
    gridColumn: `${startColumn} / ${endColumn}`,
    gridRow: `${startRow} / ${endRow}`,
  };
};

type GridProps = {
  type?: "centered" | "full-width";
  alignContent?: "stretch" | "start";
  rowSetup?: string;
  numberOfColumns?: number;
  noGutter?: boolean;
};

export const getGridContainerSX = ({
  noGutter = false,
  numberOfColumns = 12,
  rowSetup = "auto",
  type = "full-width",
  alignContent = "stretch",
  theme,
}: GridProps & { theme: Theme }) => ({
  height: "100%",
  width: "100%",
  display: "grid",
  gap: noGutter ? theme.spacing(3) : "0",
  gridTemplateColumns: numberOfColumns
    ? `repeat(${numberOfColumns}, 1fr)`
    : "repeat(8, minmax(28px, 1fr))",
  gridTemplateRows: rowSetup,
  padding: type === "full-width" ? 0 : `0 ${theme.spacing(3)}`,
  alignContent: alignContent === "stretch" ? "stretch" : "start",
  margin: "auto",
  maxWidth: type === "centered" ? "min(100%, 1152px)" : "100%",
  "@media screen and (min-width: 900px)": {
    gridTemplateColumns: "repeat(12, 1fr)",
  },
});
