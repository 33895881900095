import { useEffect, useState } from "react";

type WindowSizeProps = {
  width: number;
  height: number;
};

const useWindowSize = (): WindowSizeProps => {
  const getSize = () => {
    if (typeof window !== "undefined") {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }
    return {
      width: 0,
      height: 0,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only need to run once
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
